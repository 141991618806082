const questionBank = [
  {
    id: 1,
    question:
      "Which of these romantic relationships would you prefer right now?",
    optionsList: [
      { answer: "One with lots of chemistry but no security" },
      { answer: "One with plenty of security but no chemistry " },
    ],
  },
  {
    id: 2,
    question: "Who would you rather be betrayed by?",
    optionsList: [
      { answer: "Someone I trust but don't love" },
      { answer: "Someone I love but don't trust" },
    ],
  },
  {
    id: 3,
    question: "You find out your partner is cheating on you, what do you do?",
    optionsList: [
      { answer: "Confront them and break up" },
      { answer: "Ghost them" },
    ],
  },
  {
    id: 4,
    question:
      "You arrive at the cinema to watch a highly anticipated movie and realize you're seated right next to your ex. Do you stay?",
    optionsList: [
      { answer: "Hell no" },
      { answer: "I paid for my ticket so I'm staying" },
    ],
  },
  {
    id: 5,
    question:
      "If you had the opportunity to date someone with the same personality as you, would you do it?",
    optionsList: [{ answer: "Yes" }, { answer: "Absolutely not" }],
  },
  {
    id: 6,
    question: "What was the biggest complaint from your last romantic partner?",
    optionsList: [
      { answer: "We argued too much" },
      { answer: "Our relationship goals were mismatched" },
    ],
  },
  {
    id: 7,
    question: "Which romantic getaway would you rather go on?",
    optionsList: [
      { answer: "Backpacking through the mountains" },
      { answer: "Bikini and the beach" },
    ],
  },
  {
    id: 8,
    question: "How long did your last relationship last?",
    optionsList: [
      { answer: "Less than 2 years" },
      { answer: "It's still going" },
    ],
  },
  {
    id: 9,
    question: "What is the best quality you bring to a relationship?",
    optionsList: [{ answer: "My openness" }, { answer: "My empathy" }],
  },
  {
    id: 10,
    question: "What is your least favorite thing about first dates",
    optionsList: [
      { answer: "Getting them out of their shell" },
      { answer: "Not knowing what to say" },
    ],
  },
  {
    id: 11,
    question: "How do you know when you're in love?",
    optionsList: [
      { answer: "Butterflies and lies" },
      { answer: "When I can talk about my future with them" },
    ],
  },
  {
    id: 12,
    question: "How frequently do you see someone you're serious about?",
    optionsList: [
      { answer: "Everyday/Most days" },
      { answer: "Once/twice a week" },
    ],
  },
  {
    id: 13,
    question: "Do you believe you can find love on a dating app?",
    optionsList: [
      { answer: "Yes, I'm open minded" },
      { answer: "Have you watched Tinder Swindler?" },
    ],
  },
];

const shuffled = questionBank.sort(() => 0.5 - Math.random());
export const selectedQuestions = shuffled.slice(0, 5);

const answerBank = [
  {
    id: 1,
    answer: "Orange Tea",
    delight: "you're a wonderful person with a lot of scars💖💘",
    text: "Despite giving it your all, love has not always been kind to you. You love with all of your heart, so you deserve a love that makes you feel warm and fuzzy.",
  },
  {
    id: 2,
    answer: "Lemon Tea",
    delight:
      "you are a brave person who deserves all the good fortune that comes your way💖💘",
    text: "We're here to remind you that relationships aren't all bad; if you give it time, things will eventually work out for you.",
  },
  {
    id: 3,
    answer: "Vitalitea",
    delight: "you are such a kind and dependable person 💖💘",
    text: "Maintain the same attitude when it comes to love, and you'll go a long way. If you were Ketepa Tea, you'd be Vitalitea. Very strong and reliable. ",
  },
  {
    id: 4,
    answer: "Forest Fruit",
    delight: "you are a special soul.",
    text: "So passionate and enthusiastic. Your love burns like fire on the flames. Everyone should be as enthusiastic about love as you are.",
  },
  {
    id: 5,
    answer: "Jani Green",
    delight: "you are what everyone wants💖💘",
    text: "You are particular and know exactly what you want. You speak your mind and make everything clear. Keep up the good work; you'll save a lot of people and misery.",
  },
];

export const answer = answerBank[Math.floor(Math.random() * answerBank.length)];

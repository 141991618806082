import { selectedQuestions } from "../quizeService";
import { v4 as uuidv4 } from "uuid";

export const Question = (props) => {
  return (
    <div className="wrapper-bg">
      <div className="wrapper-bg-before">
        <div className="top-bar-yellow">
          <a href="/" className="logo">
            <img
              src="images/Heritage-logo.png"
              width="51px"
              height="51px"
              alt=""
            />
          </a>
        </div>
        <div className="centre">
          <form className="frmLead" action="#" method="post">
            <div className="gender-selection">
              <h2 className="spacing">
                {selectedQuestions[props.currentIndex].question}
              </h2>
              <h3>Tap your answer</h3>
              <a href="/" className="pic">
                <img src="images/date.png" alt="man" />
              </a>
              <div className="interested">
                {selectedQuestions[props.currentIndex].optionsList.map(
                  (option) => (
                    <label
                      key={uuidv4()}
                      onClick={props.handleCurrentIndex}
                      className="container-radio"
                    >
                      {option.answer}
                      <input type="radio" name="f-date" />
                      <span className="checkmark" />
                    </label>
                  )
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

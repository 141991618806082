export const Start = (props) => {
  return (
    <div className="App">
      <div className="wrapper-bg first-page">
        <div className="top-bar-yellow">
          <a href="/" className="logo">
            <img
              src="/images/Heritage-logo.png"
              width="51px"
              height="51px"
              alt="Ketepa Logo"
            />
          </a>
        </div>
        <div className="box-text centre">
          <h1> What kind of lover are you?</h1>
        </div>
        <a href="/" className="man-w">
          <img src="/images/man-woman.png" width="548" height="702" alt="man" />
        </a>
      </div>
      <span className="box-text start first-page">
        <h2> Take the quiz to find out</h2>
        <button onClick={props.handleQuizeStart} className="btn btn-primary">
          <span>Start</span>
        </button>
      </span>
    </div>
  );
};

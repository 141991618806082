import { answer } from "../answerService";

export const Result = () => {
  const saved = localStorage.getItem("user");
  const user = JSON.parse(saved);

  return (
    <div className="results">
      <div className="wrapper-bg">
        <div className="wrapper-bg-before">
          <div className="top-bar-yellow">
            <a href="/" className="logo">
              <img
                src="images/Heritage-logo.png"
                width="51px"
                height="51px"
                alt="Logo"
              />
            </a>
          </div>
          <div className="wrapper white">
            <span className="ttle">
              {user.name}, {answer.delight}
            </span>
            <p>{answer.text}</p>
            <p>
              If you were a Ketepa tea, you'd definitely be {answer.answer},
              because you always follow your heart.
            </p>
            <a href="/" className="chai">
              <img src="images/Heart-chai.png" alt="man" />
            </a>
            <div className="share-results">
              <p> Share your results. Let people know you are a catch.</p>
            </div>
            <div className="tbl">
              <div className="col left">
                <div className="social">
                  <ul style={{ display: "block" }}>
                    <li>
                      <a href="/" className="icon icon-fb" />
                    </li>
                    <li>
                      <a href="/" className="icon icon-ig" />
                    </li>
                    <li>
                      <a href="/" className="icon icon-twitter" />
                    </li>
                    <li>
                      <a href="/" className="icon icon-whatsapp" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col right">
                <a href="/" className="btn btn-primary red">
                  <span>Play again</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

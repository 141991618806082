import createLocalStorateHook from "use-local-storage-state";

const useUser = createLocalStorateHook("user", {
  defaultValue: [{ name: "", age: "", gender: "" }],
});

export const Persona = (props) => {
  const [user, setUser] = useUser();

  function handleChange(e) {
    setUser({ name: e.target.value });
  }

  return (
    <div className="wrapper-bg">
      <div className="wrapper-bg-before">
        <div className="top-bar-yellow">
          <a href="/" className="logo">
            <img
              src="images/Heritage-logo.png"
              width="51px"
              height="51px"
              alt="Logo"
            />
          </a>
        </div>
        <div className="centre">
          <form className="frmLead">
            <div className="gender-selection">
              <h2 className="spacing"> First things first</h2>
              <h2 className="spacing">Are you a man or woman?</h2>
              <label className="container-radio">
                Man
                <input type="radio" defaultChecked="checked" name="gender" />
                <span className="checkmark" />
              </label>
              <label className="container-radio">
                Woman
                <input type="radio" name="gender" />
                <span className="checkmark" />
              </label>
              <label className="container-radio">
                Rather not say
                <input type="radio" name="gender" />
                <span className="checkmark" />
              </label>
            </div>
            <div className="age-selection padding-top-bottom">
              <h2 className="small-spacing">How old are you?</h2>
              <p className="spacing">
                We just want to make sure you're not up past your bedtime{" "}
              </p>
              <label className="container-radio">
                18 - 25
                <input type="radio" defaultChecked="checked" name="age" />
                <span className="checkmark" />
              </label>
              <label className="container-radio">
                26 - 30
                <input type="radio" name="age" />
                <span className="checkmark" />
              </label>
              <label className="container-radio">
                31 - 36
                <input type="radio" name="age" />
                <span className="checkmark" />
              </label>
              <label className="container-radio">
                37 +
                <input type="radio" name="age" />
                <span className="checkmark" />
              </label>
            </div>
            <div className="name padding-top-bottom">
              <h2> Your name</h2>
              <p>This helps us personalize your results</p>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="textbox"
                  id="fullname"
                  name="name"
                  onChange={handleChange}
                  maxLength={50}
                />
                <label htmlFor="fullname" className="input-label">
                  <span className="label">Type your name here</span>
                </label>
              </div>
            </div>
            <span className="box-text start">
              <h2> Proceed to the quiz</h2>
              <button
                onClick={props.handleCurrentIndex}
                className="btn btn-primary"
              >
                <span>Start</span>
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import "./App.css";
import { Start } from "./Screens/start";
import { Persona } from "./Screens/persona";
import { Result } from "./Screens/result";
import { Question } from "./Screens/question";

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quizeStarted, setQuizeStarted] = useState(false);

  function handleQuizeStart() {
    setQuizeStarted(true);
  }

  function handleCurrentIndex() {
    setCurrentIndex(currentIndex + 1);
    console.log(currentIndex);
  }

  if (quizeStarted === true) {
    if (currentIndex === 0) {
      return <Persona handleCurrentIndex={handleCurrentIndex} />;
    }

    if (currentIndex < 5) {
      return (
        <Question
          currentIndex={currentIndex}
          handleCurrentIndex={handleCurrentIndex}
        />
      );
    }

    return <Result />;
  }

  return <Start handleQuizeStart={handleQuizeStart} />;
}

export default App;
